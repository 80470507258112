import React from "react";
import styles from "./styles.module.scss";

import { ReactComponent as TodoIcon } from "./../../PlanDetails/ActionItems/Category//todo.svg";
import { ReactComponent as InProgessIcon } from "./../../PlanDetails/ActionItems/Category//inProgress.svg";
import { ReactComponent as OnHoldIcon } from "./../../PlanDetails/ActionItems/Category//onHold.svg";
import { ReactComponent as CompleteIcon } from "./../../PlanDetails/ActionItems/Category/complete.svg";
import { ReactComponent as CancelIcon } from "./../../PlanDetails/ActionItems/Category/canceled.svg";
import { ReactComponent as CalanderIcon } from "./../../PlanDetails/ActionItems/Category/calander.svg";
import { ReactComponent as StarIcon } from "assets/svg/star.svg";
import { ReactComponent as ClockIcon } from "assets/svg/clock.svg";
import moment from "moment";
import { getTime } from "../../../utils/utility";

export default function TaskCardView({
  data,
  status,
  SetData,
  setIsNoteModel,
}) {
  const formateStatus = (status) => {
    switch (status) {
      case "1":
        return {
          name: "To Do",
          Icon: <TodoIcon />,
          color: "#ff99001a",
        };

      case "2":
        return {
          name: "In Progress",
          Icon: <InProgessIcon />,
          color: "#1271a61a",
        };

      case "3":
        return {
          name: "On Hold",
          Icon: <OnHoldIcon />,
          color: "#E5E5E5",
        };

      case "4":
        return {
          name: "Complete",
          Icon: <CompleteIcon />,
          color: "#59a3101a",
        };

      case "5":
        return {
          name: "Canceled",
          Icon: <CancelIcon />,
          color: "#f000001a",
        };

      default:
        return {
          name: "To Do",
          Icon: <TodoIcon />,
          color: "#ff99001a",
        };
    }
  };

  const sortData = data?.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  const Status = formateStatus(status || 1);

  return (
    <>
      <div className={styles.cardViewWrapper}>
        <div className={styles.cardViewHeader}>
          <div
            className={styles.status}
            style={{
              backgroundColor: Status.color,
            }}
          >
            {Status?.name === "To Do" ? (
              <TodoIcon />
            ) : Status?.name === "In Progress" ? (
              <InProgessIcon />
            ) : Status?.name === "On Hold" ? (
              <OnHoldIcon />
            ) : Status?.name === "Complete" ? (
              <CompleteIcon />
            ) : (
              <CancelIcon />
            )}
            <h3>{Status.name}</h3>
            <h5>{data?.length}</h5>
          </div>
        </div>

        <div className={styles.cards}>
          {sortData?.map((item, index) => {
            const responsible_person = item?.responsible_person;
            const duration = item?.duration ? getTime(item?.duration) : "";
            const noProject = item?.project_name === "No Project Plan";

            const UserName =
              responsible_person?.first_name && responsible_person?.last_name
                ? responsible_person?.first_name.substring(0, 1) +
                  responsible_person?.last_name.substring(0, 1)
                : responsible_person?.name
                ? responsible_person?.name.split(" ").length > 1
                  ? responsible_person.name.split(" ")[0].substring(0, 1) +
                    responsible_person.name.split(" ")[1].substring(0, 1)
                  : responsible_person.name.substring(0, 2)
                : responsible_person?.email?.substring(0, 2);

            return (
              <div
                className={styles.card}
                key={index}
                style={{
                  borderLeftColor: noProject ? "#A3A3A3" : "#1c8b7e",
                  cursor: "pointer",
                }}
                onClick={() => {
                  SetData(item);
                  setIsNoteModel(true);
                }}
              >
                <div className={styles.top}>
                  {item?.status == "1" ? (
                    <TodoIcon />
                  ) : item?.status == "2" ? (
                    <InProgessIcon />
                  ) : item?.status == "3" ? (
                    <OnHoldIcon />
                  ) : item?.status == "4" ? (
                    <CompleteIcon />
                  ) : (
                    <CancelIcon />
                  )}

                  <h4>{item?.name}</h4>
                </div>
                <div className={styles.center}>
                  <div className={styles.weight}>
                    <StarIcon />

                    <span>{item?.weight}</span>
                  </div>
                  {/* <div className={styles.perioty}>High</div> */}
                </div>
                <div className={styles.bottom}>
                  <div className={styles.dates}>
                    {item?.start_line && item?.dead_line && (
                      <h4>
                        <CalanderIcon />
                        {`${moment(item?.start_line).format(
                          "MMM D"
                        )} - ${moment(item?.dead_line).format("MMM D")}`}
                      </h4>
                    )}

                    <h4>
                      <ClockIcon />
                      {duration}
                    </h4>
                  </div>

                  <div className={styles.assigne}>{UserName}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
