import { useState, FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import moment from "moment";

import {
  Wrapper,
  Head,
  HeadItem,
  Body,
  CustomizeHeader,
  CustomizePopover,
  CustomizeItem,
  CustomizeItems,
  Container,
  AIGenerationLoading,
} from "./styled";

import Category, { AddCategory } from "./Category";
import AdjustWeight from "../AdjustWeight";

import { IPlan } from "./@types";

// @ts-ignore
import { changeCategoryOrder } from "modules/actions/PlanActions";

// import expandIcon from './expand.svg';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { ReactComponent as ExpandIcon } from "./expand.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as Setting } from "assets/svg/setting.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as Dragding } from "assets/svg/draging.svg";
import { ReactComponent as SettingGray } from "assets/svg/settingGray.svg";

// @ts-ignore
import {
  createPlanCategory,
  updateCustomizeColumn,
  // @ts-ignore
} from "modules/actions/PlanActions";
import { Popover, Switch } from "antd";

  // @ts-ignore
  import StarsLoading from "../../../assets/svg/yellow-stars.svg"
  // @ts-ignore
import LoadingGif from "../../../assets/gifs/assistant-loader.gif"

interface IActionItems {
  planDetails: IPlan;
  isShared: boolean;
  isShort: boolean;
  isExpandAllNotes: boolean;
  isPlanAIGenerated: boolean;
  isAIGeneratedPlanLoading: boolean;
}

const ActionItems: FC<IActionItems> = ({
  planDetails,
  isShared,
  isShort,
  isExpandAllNotes,
  isAIGeneratedPlanLoading
}) => {
  const dispatch = useDispatch();
  const mainContainer = useRef<HTMLDivElement>(null);
  const {
    customizeColumn,
    // @ts-ignore
  } = useSelector((state) => state.plan.plan);

  const [activeKey, setActiveKey] = useState<
    { id: string; isExpand: boolean }[]
  >([]);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isAdjustWeightActive, setIsAdjustWeightActive] = useState(false);
  const [isCustomizePopoverActive, setIsCustomizePopoverActive] =
    useState(false);

  const [customizeColumns, setCustomizeColumns] = useState([
    {
      key: "weight",
      title: "Weight",
      isVisible: true,
    },
    {
      key: "plan_progress",
      title: "Plan Progress",
      isVisible: true,
    },
    {
      key: "status",
      title: "Status",
      isVisible: true,
    },
    {
      key: "measurement",
      title: "Measurement",
      isVisible: true,
    },
    {
      key: "start_date",
      title: "Start Date",
      isVisible: true,
    },
    {
      key: "due_date",
      title: "Due Date",
      isVisible: true,
    },
    {
      key: "duration",
      title: "Duration",
      isVisible: true,
    },
    {
      key: "assignee",
      title: "Assignee",
      isVisible: true,
    },
  ]);

  useEffect(() => {
    const updatedActiveKey = planDetails.categories.map((category) => {
      const existingKey = activeKey.find(
        (keyItem) => keyItem.id === category.id.toString()
      );
      return existingKey || { id: category.id.toString(), isExpand: true };
    });

    // Ensure we're not losing any existing keys that might not be in the current planDetails.categories
    const existingIds = planDetails.categories.map((category) =>
      category.id.toString()
    );
    const preservedKeys = activeKey.filter((keyItem) =>
      existingIds.includes(keyItem.id)
    );

    setActiveKey([...new Set([...updatedActiveKey, ...preservedKeys])]);
  }, [planDetails]);

  const handleToggle = (id: string) => {
    setActiveKey(
      activeKey.map((keyItem) =>
        keyItem.id === id
          ? { ...keyItem, isExpand: !keyItem.isExpand }
          : keyItem
      )
    );
  };

  const handleAddCategory = () => {
    setIsAddCategory(!isAddCategory);
  };

  const handleCategoryCreate = (value: string) => {
    if (!value) {
      setIsAddCategory(false);
      return;
    }

    const payload = {
      name: value,
      start_line: moment(planDetails?.start_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      dead_line: moment(planDetails?.dead_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      project_plan: planDetails?.id,
    };

    dispatch(createPlanCategory(payload, handleCategoryCreateCallback));
  };


  const handleCategoryCreateCallback = () => {
    setIsAddCategory(false);
  };

  const handleCollapseExpandAll = () => {
    if (
      activeKey.filter((keyItem) => keyItem.isExpand).length ===
      planDetails.categories.length
    ) {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: false }))
      );
    } else {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: true }))
      );
    }
  };

  const handleAdjustWeightToggle = () => {
    setIsAdjustWeightActive(!isAdjustWeightActive);
  };

  const onDragEndHandle = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside any list
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // If it's in the same category, reorder items within the category
    if (sourceCategoryId === destinationCategoryId) {
      handleReorderWithinCategory(
        sourceCategoryId,
        source.index,
        destination.index
      );
    } else {
      // If moved to a different category, move between categories
      handleMoveBetweenCategories(
        sourceCategoryId,
        destinationCategoryId,
        source.index,
        destination.index
      );
    }
  };

  // Reordering within the same category
  const handleReorderWithinCategory = (
    categoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];
    const category = updatedCategories.find(
      (cat) => cat.id.toString() === categoryId
    );

    if (!category) return;

    const reorderedItems = Array.from(category.action_items);
    const [movedItem] = reorderedItems.splice(sourceIndex, 1);
    reorderedItems.splice(destinationIndex, 0, movedItem);

    // Update the category with the new order of action items
    category.action_items = reorderedItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  // Moving between different categories
  const handleMoveBetweenCategories = (
    sourceCategoryId: any,
    destinationCategoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];

    const sourceCategory = updatedCategories.find(
      (cat) => cat.id.toString() === sourceCategoryId
    );
    const destinationCategory = updatedCategories.find(
      (cat) => cat.id.toString() === destinationCategoryId
    );

    if (!sourceCategory || !destinationCategory) return;

    const sourceItems = Array.from(sourceCategory.action_items);
    const [movedItem] = sourceItems.splice(sourceIndex, 1);

    const destinationItems = Array.from(destinationCategory.action_items);
    destinationItems.splice(destinationIndex, 0, movedItem);

    // Update the action items for source and destination categories
    sourceCategory.action_items = sourceItems;
    destinationCategory.action_items = destinationItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  const sendActionsToAPI = (updatedCategories: any) => {
    // Construct the payload based on the updated categories
    const actions: any = [];

    updatedCategories.forEach((category: any) => {
      category.action_items.forEach((item: any, index: number) => {
        actions.push({
          id: item.id,
          order_id: index,
          category: category.id,
        });
      });
    });

    const payload = { actions };

    dispatch(changeCategoryOrder(payload, planDetails?.id));
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // If there's no destination (item dropped outside the list), do nothing
    if (!destination) return;

    // Reorder the list
    const reorderedColumns = Array.from(customizeColumns);
    const [moved] = reorderedColumns.splice(source.index, 1);
    reorderedColumns.splice(destination.index, 0, moved);

    const payload = {
      project_plan: planDetails.id,
      column: reorderedColumns
        ?.filter((item) => item.isVisible)
        ?.map((item) => item.key),
    };

    if (!isShared) {
      dispatch(updateCustomizeColumn(payload));
    }
    setCustomizeColumns(reorderedColumns);
  };

  const isVisibleLength = customizeColumns.filter((column) => column.isVisible);

  useEffect(() => {
    if (customizeColumn) {
      const visibleColumns =
        customizeColumn?.[0]?.column?.length > 0
          ? customizeColumn?.[0]?.column
          : customizeColumns.map((item) => item.key);

      const formattedColumns = customizeColumns.map((column) => ({
        ...column,
        isVisible: visibleColumns?.includes(column.key),
      }));

      const sortedColumns = [...formattedColumns].sort((a, b) => {
        const indexA = visibleColumns?.indexOf(a.key);
        const indexB = visibleColumns?.indexOf(b.key);
        return (
          (indexA === -1 ? Number.MAX_VALUE : indexA) -
          (indexB === -1 ? Number.MAX_VALUE : indexB)
        );
      });

      setCustomizeColumns(sortedColumns);
    }
  }, [customizeColumn]);

  if(isAIGeneratedPlanLoading) {
    return <AIGenerationLoading>
      <img src={StarsLoading} alt="Generating" />
      <p>Suggesting Project Plan...</p>
      <img src={LoadingGif} alt="Generating" />
    </AIGenerationLoading>
  }

  return (
    <Container ref={mainContainer}>
      <Wrapper>
        <Head $color="#1C8B7E" isShort={isShort || isVisibleLength.length < 5}>
          <HeadItem>
            <input type="checkbox" />
          </HeadItem>

          <HeadItem>
            <ExpandIcon
              className="cursor-pointer"
              onClick={handleCollapseExpandAll}
            />
          </HeadItem>

          <HeadItem>
            Category & Action Items
            {!isShared && (
              <span
                onClick={handleAddCategory}
                className="add-catrgory"
                style={{
                  opacity: isAddCategory ? 0.5 : 1,
                  pointerEvents: isAddCategory ? "none" : "auto",
                  cursor: isAddCategory ? "not-allowed" : "pointer",
                }}
              >
                <PlusIcon />
                Add Category
              </span>
            )}
          </HeadItem>

          {customizeColumns?.map((column) =>
            column.isVisible ? (
              <HeadItem
                key={column.key}
                $center={
                  column?.key === "assignee" || column?.key === "duration"
                    ? true
                    : false
                }
              >
                {column.title}
              </HeadItem>
            ) : null
          )}

          <HeadItem>
            <div className="cursor-pointer">
              <Popover
                content={
                  <>
                    <CustomizePopover
                      style={{
                        height:
                          mainContainer.current &&
                          mainContainer.current?.offsetHeight > 350
                            ? 350
                            : mainContainer.current?.offsetHeight
                            ? mainContainer.current?.offsetHeight - 60
                            : 350,
                      }}
                    >
                      <CustomizeHeader>
                        <h6>Customize Popover</h6>
                        <CloseIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsCustomizePopoverActive(false)}
                        />
                      </CustomizeHeader>

                      <h5>Columns</h5>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="columns">
                          {(provided) => (
                            <CustomizeItems
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {customizeColumns.map((column, index) => (
                                <Draggable
                                  key={column.key}
                                  draggableId={column.key}
                                  index={index}
                                >
                                  {(provided) => (
                                    <CustomizeItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <div className="customizeItem-title">
                                        {/* Apply drag handle only to this icon */}
                                        <div
                                          {...provided.dragHandleProps}
                                          style={{
                                            cursor: "grab",
                                            display: "inline-block",
                                          }}
                                        >
                                          <Dragding />{" "}
                                          {/* Your drag icon here */}
                                        </div>

                                        <h4>{column.title}</h4>
                                      </div>
                                      <Switch
                                        checked={column.isVisible}
                                        size="small"
                                        onChange={(value) => {
                                          const UpdatedColumns =
                                            customizeColumns?.map((item) =>
                                              item.key === column.key
                                                ? {
                                                    ...item,
                                                    isVisible: !item.isVisible,
                                                  }
                                                : item
                                            );

                                          const payload = {
                                            project_plan: planDetails.id,
                                            column: UpdatedColumns?.filter(
                                              (item) => item.isVisible
                                            ).map((item) => item.key),
                                          };

                                          dispatch(
                                            updateCustomizeColumn(payload)
                                          );

                                          setCustomizeColumns(UpdatedColumns);

                                          if (value) {
                                            setTimeout(() => {
                                              mainContainer.current?.scrollTo({
                                                top: 0,
                                                left: 10000,
                                                behavior: "smooth",
                                              });
                                            }, 200);
                                          }
                                        }}
                                      />
                                    </CustomizeItem>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </CustomizeItems>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </CustomizePopover>
                  </>
                }
                open={isCustomizePopoverActive}
                onOpenChange={(visible) => setIsCustomizePopoverActive(visible)}
                trigger="click"
                overlayClassName="CustomizePopover"
                placement="bottomRight"
                builtinPlacements={{
                  bottomRight: {
                    points: ["tr", "br"],
                    offset: [0, -6],
                  },
                }}
                // @ts-ignore
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {isCustomizePopoverActive ? (
                  <Setting
                    onClick={() =>
                      setIsCustomizePopoverActive(!isCustomizePopoverActive)
                    }
                  />
                ) : (
                  <SettingGray
                    onClick={() =>
                      setIsCustomizePopoverActive(!isCustomizePopoverActive)
                    }
                  />
                )}
              </Popover>
            </div>
          </HeadItem>
        </Head>

      <Accordion
        as={Body}
        activeKey={activeKey
          .filter((item) => item.isExpand)
          .map((item) => item.id)}
      >
        {isAddCategory ? (
          <AddCategory
            isShared={isShared}
            isShort={isShort}
            setIsAddCategory={setIsAddCategory}
            onCreate={handleCategoryCreate}
          />
        ) : null}
        <DragDropContext onDragEnd={onDragEndHandle}>
          {planDetails.categories.map((item) => (
            <Droppable droppableId={item.id.toString()} key={item.id}>
              {(provided) => (
                <Category
                  data={item}
                  additionalData={{
                    plan_start_date: planDetails.start_line,
                    plan_end_date: planDetails.dead_line,
                  }}
                  customizeColumns={customizeColumns}
                  isActive={activeKey
                    .filter((a) => a.isExpand)
                    .map((a) => a.id)
                    .includes(item.id.toString())}
                  onToggle={handleToggle}
                  onAdjustWeight={handleAdjustWeightToggle}
                  isShared={isShared}
                  isExpandAllNotes={isExpandAllNotes}
                  isShort={isShort || isVisibleLength.length < 5}
                  key={item.id}
                  provided={provided}
                  isAddCategory={isAddCategory}
                  PlanDetails={planDetails}
                  setIsAddCategory={setIsAddCategory}
                />
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Accordion>

        <AdjustWeight
          isAdjustWeightActive={isAdjustWeightActive}
          onClose={handleAdjustWeightToggle}
        />
      </Wrapper>
    </Container>
  );
};

export default ActionItems;
