import React, { useState } from "react";
import styles from "./styles.module.scss";
import { formatDate } from "../../../utils/helper";
import { ReactComponent as Calander } from "assets/svg/calander.svg";
import { ReactComponent as LockIcon } from "assets/svg/lock.svg";
import { ReactComponent as ArrowCollapse } from "assets/svg/arrowCollapse.svg";
import { getTime } from "utils/utility";
import { StatusBadge } from "../../../utils/utility";

import { Accordion } from "react-bootstrap";

const TaskList = ({ task, noProject ,SetData,setIsNoteModel}) => {
  const [isExpand, setIsExpand] = useState(false);

  const start_line = formatDate(task?.start_line);
  const dead_line = formatDate(task?.dead_line);

  const responsible_person = task?.responsible_person;
  const notesData = task?.notes ? task?.notes[0] : null;
  const duration = task?.duration ? getTime(task?.duration) : "";

  const UserName =
    responsible_person?.first_name && responsible_person?.last_name
      ? responsible_person?.first_name.substring(0, 1) +
        responsible_person?.last_name.substring(0, 1)
      : responsible_person?.name
      ? responsible_person?.name.split(" ").length > 1
        ? responsible_person.name.split(" ")[0].substring(0, 1) +
          responsible_person.name.split(" ")[1].substring(0, 1)
        : responsible_person.name.substring(0, 2)
      : responsible_person?.email?.substring(0, 2);

  return (
    <div
      className={styles.planList}
      style={{
        borderLeftColor: noProject ? "#A3A3A3" : "#1c8b7e",
        cursor: "pointer"
      }}
      onClick={()=>{SetData(task)
        setIsNoteModel(true)

       }}
    >
      <Accordion activeKey={isExpand ? "1" : "0"} className={styles.accordion}>
        <div className={styles.accordionTitle}>
          <ArrowCollapse
            onClick={(e) => {
              e.stopPropagation();
              setIsExpand(!isExpand);
            }}
            className={styles.arrow}
            style={{
              transform: isExpand ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <h2
            className={styles.title}
            style={{
              paddingBottom: isExpand ? "14px" : "0",
            }}
          >
            {task?.name}
          </h2>
        </div>

        <Accordion.Collapse eventKey={"1"}>
          <div className={styles.NoteContent}>
            <div className={styles.NoteHeading}>Notes :</div>
            <div className={styles.NoteText}>{notesData?.context}</div>
          </div>
        </Accordion.Collapse>
      </Accordion>

      <span className={styles.duration}>{task?.weight}</span>

      <span className={styles.status}>{StatusBadge(task?.status)}</span>

      <span className={styles.deadline}>
        {start_line ? (
          <>
            {start_line}
            <LockIcon />
          </>
        ) : (
          "--"
        )}
      </span>
      <span className={styles.deadline}>
        {dead_line ? (
          <>
            {dead_line}
            <Calander />
          </>
        ) : (
          "--"
        )}
      </span>

      <span className={styles.duration}>{duration}</span>

      <div className={styles.assignee}>
        <div className={styles.avatar}>{UserName}</div>
      </div>
    </div>
  );
};

export default TaskList;
