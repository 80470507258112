import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "./assistant.svg";
import { ReactComponent as AddIcon } from "./add.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as MinusIcon } from "./minus.svg";
import FormInput from "components/FormInput/FormInput";
// import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateAIProsCons,
  SetAIProsCons,
} from "redux/DecisionMatrix/GenerateAIProsCons/action";
import { UpdateOptionCriteria } from "redux/DecisionMatrix/UpdateOptionCriteria/action";
import Skeleton from "scenes/DecisionMatrix/components/Skeleton/skeleton";
import { websocketConnection } from "utils/websocket";
const AIAssistantModal = ({
  onClose,
  setOptionCriteriaProsCons,
  selectedCriteria,
  criteria,
  optionId,
  selectedOption,
}) => {
  const dispatch = useDispatch();
  // const {
  //   loading,
  //   success: getProsCons,
  //   error,
  // } = useSelector((state) => state.generateAIProsConsReducer);
  const { loading: loadingApply } = useSelector(
    (state) => state.updateOptionCriteriaReducer
  );
  const [isProConLoading, setIsProConLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState();
  const [pros, setPros] = useState([]);
  const [selectedCons, setSelectedCons] = useState([]);
  const [selectedPros, setSelectedPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [isTryAgain, setIsTryAgain] = useState(true)

  // useEffect(() => {
  //   // if (isPurposeLoading || isObjectiveLoading) {
  //   let timer;
  //   // if (websocketConnection) {
  //   timer = setInterval(() => {
  //     websocketConnection?.send(
  //       JSON.stringify({
  //         pong: "pong",
  //       })
  //     );
  //   }, 1000);
  //   // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // }, []);

  useEffect(() => {
    // websocketConnection?.connect();
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);
  const handleMessage = (message) => {
    // Handle the received message
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    setResponse(res?.message); // Set the received message as the response
    setErrors(res?.message?.error?.message);
    setIsProConLoading(false);
  };
  useEffect(() => {
    if (response?.Pros) {
      setPros(response?.Pros);
    }
    if (response?.Cons) {
      setCons(response?.Cons);
    }
  }, [response]);

  const getOptions = () => {
    const list = [{ value: "", label: "Select Criteria" }];
    criteria?.map((item) => {
      if(item?.title?.length > 0){
        list?.push({ value: item?.id, label: item?.title });
      }
    });
    return list;
  };

  const handleApply = () => {
    setIsTryAgain(false)
    setErrors("");
    setIsProConLoading(true);
    websocketConnection.send(
      JSON.stringify({
        endpoint: "generate_pros_and_cons",
        model_id: optionId,
        title: selectedCriteria?.title,
      })
    );
    // dispatch(GenerateAIProsCons(`?option_criteria=${optionId}`));
  };
  const handleUpdateOptionCriteria = () => {
    let body = new FormData();
    selectedCons?.length > 0 &&
      selectedCons?.map((con) =>
        body.append(`cons[${con - 1}]context`, cons[con - 1]?.Description)
      );
    selectedPros?.length > 0 &&
      selectedPros?.map((pro) =>
        body.append(`pros[${pro - 1}]context`, pros[pro - 1])
      );
    dispatch(UpdateOptionCriteria(body, optionId, true, true, onCloseAIModal));
  };

  const onCloseAIModal = () => {
    onClose();
    setSelectedCons([]);
    setSelectedPros([]);
    setPros([]);
    setCons([]);
    dispatch(SetAIProsCons(null));
  };

  const handClickCons = (index) => {
    selectedCons?.find((e) => e == index + 1)
      ? setSelectedCons(selectedCons?.filter((e) => e !== index + 1))
      : setSelectedCons([...selectedCons, index + 1]);
  };

  const handClickPros = (index) => {
    selectedPros?.find((e) => e == index + 1)
      ? setSelectedPros(selectedPros?.filter((e) => e !== index + 1))
      : setSelectedPros([...selectedPros, index + 1]);
  };
  const isDisabled = loadingApply || (selectedCons?.length === 0 && selectedPros?.length === 0) || cons?.length === 0 || pros?.length === 0;
  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onCloseAIModal}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>{selectedOption?.name}</p>
            <p className={classes.suggestText}>
              Suggest Pros/Cons for Criteria
            </p>
            <Row gutter={24}>
              <Col span={12}>
                <div className={`${classes.inputField}`}>
                  <FormInput
                    height="44px"
                    name={"selectedCriteria"}
                    error={errors ? errors : null}
                    setFieldValue={setOptionCriteriaProsCons}
                    value={selectedCriteria?.id}
                    type="select"
                    options={getOptions()}
                  />
                </div>
              </Col>
              <button
                type="button"
                disabled={isProConLoading || !selectedCriteria?.id}
                className={classes.suggest}
                onClick={handleApply}
              >
                {isProConLoading ? "Suggesting" : "Suggest"}
              </button>
            </Row>
            <Row className={classes.main}>
              <Col span={12}>
                <div className={classes.pros}>
                  <AddIcon />
                  <span>Pros</span>
                </div>
              </Col>
              <Col span={12}>
                <div className={classes.cons}>
                  <MinusIcon />
                  <span>Cons</span>
                </div>
              </Col>
            </Row>
            <div className={classes.greyBox}>
              {isProConLoading ? (
                <Skeleton />
              ) : (
                <>
                  {pros?.length === 0 && cons?.length === 0 ? (
                    <div className={classes.emptyText}>
                      "No pros/cons found, please enter generate some with AI ."
                    </div>
                  ) : (
                    <Row gutter={2}>
                      <Col span={12}>
                        {pros?.map((item, index) => (
                          <div className={classes.checkboxDiv}>
                            <Checkbox
                              onClick={() => handClickPros(index)}
                              checked={selectedPros?.find(
                                (e) => e == index + 1
                              )}
                              className={classes.checkbox}
                            />
                            <div onClick={() => handClickPros(index)}>
                              {item}
                            </div>
                          </div>
                        ))}
                      </Col>
                      <Col span={12} className={classes.boxLeft}>
                        {cons?.map((item, index) => (
                          <div className={classes.checkboxDiv}>
                            <Checkbox
                              onClick={() => handClickCons(index)}
                              checked={selectedCons?.find(
                                (e) => e == index + 1
                              )}
                              className={classes.checkbox}
                            />
                            <div onClick={() => handClickCons(index)}>
                              {item?.Description}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={classes.footer}>
            <button
              disabled={isTryAgain}
              type="button"
              className={classes.tryagain}
              onClick={handleApply}
              style={{ opacity: isTryAgain ? 0.5 : 1 }}
            >
              Try Again
            </button>
            <button
              type="button"
              disabled={isDisabled}
              onClick={handleUpdateOptionCriteria}
              style={{ opacity: isDisabled ? 0.5 : 1 }}
            >
              {loadingApply ? "Applying" : "Apply"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { AIAssistantModal };
