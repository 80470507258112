import React, { memo, useEffect, useMemo, useState } from "react";
import classes from "./select.module.scss";
import cc from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CheckedIcon } from "./checked.svg";
import { ReactComponent as AiIcon } from "./union.svg";

import { Popover } from "antd";
import { UpdateOptionCriteria } from "redux/DecisionMatrix/UpdateOptionCriteria/action";
import { useDetectClickOutside } from "utils/useDetectClickOutside";
import { ScoringRubricModal } from "../ScoringRubricModal/scoringRubricModal";
import { AIScoringRubicModal } from "../AIAssistantModal/AIScoringRubicModal";

import isEqual from 'lodash.isequal';

import { useLocation, useParams } from "react-router-dom";

const items = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const defaultRubric = `10 - Outstanding
9 - Excellent
8 - Very Good
7 - Good
6 - Above Average
5 - Average
4 - Below Average
3 - Bad
2 - Very Bad
1 - Terrible/Poor
0 - Extremely Dissatisfied
N/A - Not Applicable`;

const Select = ({
  optionCriteria,
  isShortList,
  rubric,
  isHighlighted,
  disabled,
  secondary,
  activeCriterias,

  // isHighlighted,
  isReverse = false,
  // disabled,
  isUpdateMe,
  isDecisionSample,
  // isTrue,
  // setIsTrue,
  isLeft = false,
  isBackground = false,
  onRubricSave,
  setIsUpdateMe = () => { }
}) => {
  const { decisionId } = useParams();
  const [selectValue, setSelectValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewRubric, setIsViewRubric] = useState(null);
  const [isRubricModal, setIsRubricModal] = useState(false);

  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const secure_token = query.get("secure_token");

  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });
  const onRubricSaveHandle = (_, rubrics) => {
    onRubricSave(rubrics, optionCriteria?.criteria);
    setIsViewRubric(null);

    /* dispatch(
      UpdateCriteriaRows({
        update: decision?.criteria
          ?.filter((crit) => crit?.id === optionCriteria?.criteria)
          ?.map(({ rubric, ...rest }) => {
            return { ...rest, rubric: rubrics };
          }),
        decision: decisionId,
      })
    );

    setIsViewRubric(null); */
  };

  const onRubricApplyModal = (criteria, rubric) => {
    onRubricSaveHandle(criteria, rubric);
    setIsRubricModal(false);
  };

  // const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const content = (rubric) => {
    return (
      <div className={classes.position}>
        <div
          className={cc(
            classes.PropOverContainer,
            isReverse ? classes.reverse : ""
          )}
        >
          <div className={classes.left}>
            {/* <div className={classes.wrapper}> */}
            <div className={classes.cost}>Scoring Rubric</div>
            <div className={classes.scoreSection}>
              <h2>Scores</h2>
              <span className={classes.Item}>
                {rubric ? (
                  <p className={classes.itemSelectRubric}>{rubric}</p>
                ) : (
                  <p
                    className={classes.itemSelectRubric}
                    dangerouslySetInnerHTML={{
                      __html: defaultRubric.replaceAll("\n", "<br />"),
                    }}
                  />
                )}
              </span>
            </div>
            {/* </div> */}
            <div className={classes.leftbutton}>
              <button onClick={() => setIsViewRubric(optionCriteria)}>
                EDIT RUBRIC
              </button>
              <AiIcon
                className={classes.ai_icon}
                onClick={() => setIsRubricModal(true)}
              />
            </div>
          </div>

          <div className={classes.right}>
            <ul>
              {items.map((i) => (
                <li
                  onClick={(e) => {
                    chooseValue(
                      isShortList
                        ? { second_stage_score: i.toString() }
                        : { score: i.toString() },
                      e
                    );
                  }}
                  key={i}
                  className={selectValue === i && classes.active}
                >
                  <span
                    className={cc(classes.itemSelect, {
                      [classes.green]: i / 10 >= 0.7,
                      [classes.yellow]: i / 10 <= 0.7 && i / 10 >= 0.4,
                      [classes.red]: i / 10 <= 0.4,
                      // [classes.gray]: !i
                    })}
                  >
                    {i}
                  </span>
                  {selectValue === i && <CheckedIcon />}
                </li>
              ))}

              <li
                className={selectValue === "N/A" && classes.active}
                onClick={(e) => {
                  chooseValue(
                    isShortList
                      ? { second_stage_is_na: true }
                      : { is_na: true },
                    e
                  );
                }}
              >
                N/A
                {selectValue === "N/A" && <CheckedIcon />}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (optionCriteria?.second_stage_is_na && isShortList) {
      setSelectValue("N/A");
      return;
    }
    if (optionCriteria?.second_stage_is_blank && isShortList) {
      setSelectValue("");
      return;
    }
    if (optionCriteria?.is_na && !isShortList) {
      setSelectValue("N/A");
      return;
    }
    if (optionCriteria?.is_blank && !isShortList) {
      setSelectValue("");
      return;
    }

    if (!isShortList) {
      setSelectValue(Math.round(optionCriteria?.score));
      return;
    }
    if (isShortList) {
      setSelectValue(Math.round(optionCriteria?.second_stage_score));
    }
  }, [optionCriteria, isShortList]);

  // const chooseValue = (v, event) => {
  //   if (isShortList) {
  //     setSelectValue(v?.second_stage_score || "N/A");
  //   } else {
  //     setSelectValue(v?.score || "N/A");
  //   }

  //   if (setSelectIndex) setSelectIndex(null);
  //   setIsOpen(false);

  //   if (optionCriteria) {
  //     let id = optionCriteria?.id;
  //     let body = v;

  //     dispatch(UpdateOptionCriteria(body, id, false, false));
  //   }
  // };

  const chooseValue = (v, event) => {
    // setIsTrue(true);
    if (isShortList) {
      dispatch(
        UpdateOptionCriteria(
          v?.second_stage_score
            ? { second_stage_score: v?.second_stage_score }
            : { second_stage_is_na: true },
          optionCriteria?.id,
          false,
          false,
          () => { },
          isLeft ? "leftLoading" : "rightLoading"
        )
      );
      setIsUpdateMe(!isUpdateMe);
    } else {
      dispatch(
        UpdateOptionCriteria(
          v?.score ? { score: v?.score } : { is_na: true },
          optionCriteria?.id,
          false,
          false,
          () => { },
          isLeft ? "leftLoading" : "rightLoading"
        )
      );
      setIsUpdateMe(!isUpdateMe);
    }

    // if (setSelectIndex) setSelectIndex(null);
    setIsOpen(false);
  };
  return (
    <>
      <div
        className={cc(classes.wrapper, isHighlighted && classes.highlighted, {
          [classes.secondary]: secondary,
          [classes.empty]: optionCriteria?.is_blank
        })}
        style={{
          borderColor: isBackground ? "#59A310" : optionCriteria?.score === "" ? "#feab3c" : "#e3e5e9",

        }}
      >

        <Popover
          open={!(isDecisionSample || secure_token) ? isOpen : false}
          content={content(rubric)}
          className={classes.popover}
          overlayClassName={classes.popoverNoPadding}
          placement="topRight"
          trigger="click"
        >
          <button
            type="button"
            className={classes.value}
            style={{
              background: isBackground && "#EDFDC6",
              borderColor: isBackground && "#59A310",


            }}
            onClick={() => setIsOpen(!isOpen)}
            ref={ref}
            disabled={disabled}
          >
            {optionCriteria?.score || optionCriteria?.second_stage_score || optionCriteria?.is_blank || optionCriteria?.is_na ? (
              <div
                className={cc(classes.item, {
                  [classes.green]: (optionCriteria?.score || 0) / 10 >= 0.7,
                  [classes.yellow]: (optionCriteria?.score || 0) / 10 <= 0.7 && (optionCriteria?.score || 0) / 10 >= 0.4,
                  [classes.red]: (optionCriteria?.score || 0) / 10 <= 0.4 || optionCriteria?.score === 0,
                })}
                style={{
                  color: optionCriteria?.is_na && "black"
                }}
              >
                {
                  isShortList
                    ? (optionCriteria?.second_stage_score === "0.0"
                      ? "0"
                      : optionCriteria?.is_blank
                        ? ""
                        : optionCriteria?.second_stage_is_na
                          ? "N/A"
                          : Math.round(Number(optionCriteria?.second_stage_score))
                    )
                    : (optionCriteria?.is_na
                      ? "N/A"
                      : optionCriteria?.is_blank
                        ? ""
                        : Math.round(Number(optionCriteria?.score))
                    )
                }

              </div>
            ) : (
              <p></p>
            )}
          </button>
        </Popover>
      </div>

      {isRubricModal && (
        <AIScoringRubicModal
          criteria={activeCriterias}
          handleApply={onRubricApplyModal}
          onClose={() => setIsRubricModal(false)}
          selectedCriteria={activeCriterias?.find(
            (item) => item?.id === optionCriteria?.criteria
          )}
          decisionID={decisionId}
        />
      )}
      {!!isViewRubric && (
        <ScoringRubricModal
          criteria={activeCriterias}
          onSave={onRubricSaveHandle}
          onClose={() => setIsViewRubric(false)}
        />
      )}
    </>
  );
};

const MemoizedSelect = memo(Select, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});

export { MemoizedSelect as Select };
