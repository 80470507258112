import { toast } from "react-toastify";

export const handleValidation = (
  value: number,
  name: string,
  start: number | null,
  current: number | null,
  target: number | null
) => {
  const val = Number(value);

  if (name === "start" && current !== null && val > current) {
    toast.error("Start value must be less than current value");
    return false;
  } else if (name === "start" && target !== null && val > target) {
    toast.error("Start value must be less than target value");
    return false;
  } else if (name === "current" && start !== null && val < start) {
    toast.error("Value must be greater than start value");
    return false;
  } else if (name === "current" && target !== null && val > target) {
    toast.error("Value must be less than target value");
    return false;
  } else if (name === "target" && current !== null && val < current) {
    toast.error("Target value must be greater than current value");
    return false;
  } else if (name === "target" && start !== null && val < start) {
    toast.error("Target value must be greater than start value");
    return false;
  }
  return true;
};

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export function generateUniqueId() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

  const randomPart = Math.floor(Math.random() * 1000)
    .toString()
    .padStart(3, "0");

  const uniqueId = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomPart}`;
  return uniqueId;
}

interface ColumnItem {
  key: string;
  isVisible: boolean;
}

export function isColumnVisible(
  customizeColumns: ColumnItem[],
  key: string
): boolean {
  return customizeColumns?.some(
    (item: ColumnItem) => item?.key === key && item?.isVisible
  );
}

export function getUnit(label: string): string {
  switch (label) {
    case "distance":
      return "distance_unit";
    case "energy":
      return "energy_unit";
    case "height":
      return "height_unit";
    case "time":
      return "time_unit";
    case "volume":
      return "volume_unit";
    case "weight":
      return "weight_unit";
    case "count":
      return "count_unit";
    case "scale":
      return "scale_unit";
    case "currency":
      return "currency_unit";
    case "custom":
      return "custom_unit_name";
    default:
      return "";
  }
}
